<template>
  <div>
    <DefaultRegister
      :title="$t('register.accOpeningVerification.accOpenVer')"
      :subTitle="subTitle"
      :narrative="narrative"
      :stepLineData="stepLineData"
      :stepNum="stepNum"
    >
      <template v-slot:content>
        <router-view :key="$route.fullPath" />
      </template>
    </DefaultRegister>
  </div>
</template>

<script>
import DefaultRegister from '@/components/template/register/Default';
import { registerFirstStep } from '@/util/register';
import { mapActions } from 'vuex';

export default {
  name: 'accOpeningVerificationIndx',
  components: { DefaultRegister },
  data() {
    return {
      stepLineData: [],
      form: {
        title: null,
        firstName: '',
        middleName: '',
        lastName: '',
        nationalityId: null,
        phoneCode: null,
        mobile: '',
        dob: null
      }
    };
  },
  computed: {
    routerName() {
      return this.$route.name;
    },
    stepNum() {
      return registerFirstStep[this.routerName];
    },
    subTitle() {
      return this.$t(`register.tabs.${this.routerName}`);
    },
    narrative() {
      return this.$t(`register.accOpeningVerification.${this.routerName}.narrative`, {
        link: 'https://puprime.com/account-types/'
      });
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.setData();
    },
    setData() {
      Object.entries(registerFirstStep).map(([key, val]) => {
        this.stepLineData.push({
          value: val,
          label: this.$t(`register.tabs.${key}`)
        });
      });
    }
  }
};
</script>
